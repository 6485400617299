

















import { Component, Vue } from 'nuxt-property-decorator';

import SectionStart from '@/components/section/Start/Start.vue';
import SectionWhatWeDo from '@/components/section/WhatWeDo/WhatWeDo.vue';
import SectionAboutUs from '@/components/section/AboutUs/AboutUs.vue';
import SectionCareers from '@/components/section/Careers.vue';
import SectionContact from '@/components/section/Contact.vue';
const BackgroundGradientComponent = () => import('@/components/BackgroundGradient/BackgroundGradient.vue');

@Component({
  components: {
    BackgroundGradientComponent,
    SectionStart,
    SectionWhatWeDo,
    SectionAboutUs,
    SectionCareers,
    SectionContact,
  },
})
export default class IndexPage extends Vue {
  sections = [
    {
      title: 'Start',
      hash: 'start',
      component: SectionStart,
      lightBackground: false,
    },
    {
      title: 'What we do',
      hash: 'what-we-do',
      component: SectionWhatWeDo,
      lightBackground: true,
    },
    {
      title: 'About us',
      hash: 'about-us',
      component: SectionAboutUs,
      lightBackground: false,
    },
    {
      title: 'Careers',
      hash: 'careers',
      component: SectionCareers,
      lightBackground: false,
    },
    {
      title: 'Contact',
      hash: 'contact',
      component: SectionContact,
      lightBackground: true,
    },
  ];

  get appIsReady () {
    return this.$store.state.app.isReady;
  }
}
