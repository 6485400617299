



















import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class SectionWhatWeDoCard extends Vue {
  @Prop({ type: Number, required: true })
    index!: number;

  @Prop({ type: String, required: true })
    domId!: string;

  @Prop({ type: String, required: true })
    title!: string;

  @Prop({ type: String, required: true })
    description!: string;

  @Prop(Array)
    labels!: string[];

  mounted () {
    const el = this.$refs.el as HTMLElement;
    const tl = gsap.timeline({ defaults: { ease: 'none' } });
    let startOpacity = 0;
    let startScale = 0.85;
    let endOpacity = 0;
    let endScale = 0.85;
    if (this.index === 0) {
      startOpacity = 1;
      startScale = 1;
    }
    if (this.index === 4) {
      endOpacity = 1;
      endScale = 1;
    }

    tl.set(el, { opacity: startOpacity, scale: startScale })
      .to(el, { opacity: 1, scale: 1 })
      .to(el, { opacity: endOpacity, scale: endScale });

    ScrollTrigger.create({ animation: tl, trigger: el, scrub: true });
    ScrollTrigger.create({
      trigger: el,
      start: 'top center',
      end: 'bottom center',
      onToggle: (e) => {
        if (e.isActive)
          this.$emit('isActive', this.index);
      },
    });
  }
}
