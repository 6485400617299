














import { Component, Vue, Watch } from 'nuxt-property-decorator';

@Component
export default class SectionStart extends Vue {
  sectionMinHeight = 0;

  get appVh () {
    return this.$store.state.app.bounds.vh;
  }

  mounted () {
    if (process.client)
      this.sectionMinHeight = document.documentElement.clientHeight;
  }

  @Watch('appVh')
  onVhChange () {
    this.sectionMinHeight = document.documentElement.clientHeight;
  }

  get containerMinHeight () {
    return `min-height: ${this.sectionMinHeight}px`;
  }
}
