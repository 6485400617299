








import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { gsap } from 'gsap';

@Component
export default class ContactListLink extends Vue {
  @Prop(String)
    text!: string;

  @Prop(String)
    href!: string;

  tl;
  icon;

  mounted () {
    this.icon = (this.$refs.icon as Vue).$el as HTMLElement;
    this.tl = gsap.timeline({ paused: true, defaults: { duration: 0.35 } });
    this.tl
      .to(this.icon, { xPercent: 100, yPercent: -100, ease: 'power4.in' })
      .set(this.icon, { xPercent: -100, yPercent: 100 })
      .to(this.icon, { xPercent: 0, yPercent: 0, ease: 'power4.out' });
  }

  animateArrow () {
    this.tl.play();
    this.tl.progress(0);
  }
}
