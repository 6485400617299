





import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class SectionWrapper extends Vue {
  @Prop(Number)
    sectionIndex!: number;

  @Prop(Boolean)
    lightBackground!: boolean;

  @Prop(String)
    hash!: string;

  mounted () {
    const el = this.$refs.el as HTMLElement;
    const topTriggerPos = this.$store.state.navigation.scrollTriggerPos;
    ScrollTrigger.create({
      trigger: el,
      start: 'top center',
      end: 'bottom center',
      onToggle: (e) => {
        if (e.isActive) {
          this.$store.commit('navigation/setCurrentSection', this.sectionIndex);
          ScrollTrigger.refresh();
        }
      },
    });
    ScrollTrigger.create({
      trigger: el,
      start: `top ${topTriggerPos}`,
      end: `bottom ${topTriggerPos}`,
      invalidateOnRefresh: true,
      onToggle: (e) => {
        if (e.isActive)
          this.$store.commit('navigation/setLightTheme', this.lightBackground);
      },
    });
    ScrollTrigger.create({
      trigger: el,
      start: 'top 80%',
      onEnter: (e) => {
        this.$nuxt.$emit(`animateSection:${this.hash}`);
        e.kill();
      },
    });
  }
}
