























import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class ContactList extends Vue {
  email = 'hello@theodoratech.se';
  phone = '+46 702 58 98 74';
  linkedInLink = 'https://www.linkedin.com/company/theodora-tech/';

  get formattedPhone () {
    return this.phone.replace(/[^\d]/g, '');
  }
}
