import { render, staticRenderFns } from "./WhatWeDo.vue?vue&type=template&id=f9724074&"
import script from "./WhatWeDo.vue?vue&type=script&lang=ts&"
export * from "./WhatWeDo.vue?vue&type=script&lang=ts&"
import style0 from "./WhatWeDo.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AnimateHeading: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateHeading.vue').default,SectionWhatWeDoNavigation: require('/Users/markussh/git/theodora-tech-website/src/components/section/WhatWeDo/Navigation.vue').default,SectionWhatWeDoCard: require('/Users/markussh/git/theodora-tech-website/src/components/section/WhatWeDo/Card.vue').default,SectionWhatWeDoScrollList: require('/Users/markussh/git/theodora-tech-website/src/components/section/WhatWeDo/ScrollList.vue').default,AnimateFade: require('/Users/markussh/git/theodora-tech-website/src/components/animate/AnimateFade.vue').default})
