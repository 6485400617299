


















import { Component, Vue, Watch } from 'nuxt-property-decorator';
import { gsap } from 'gsap';

interface Link {
  hash: string,
  title: string,
  description?: string,
}

@Component
export default class Navigation extends Vue {
  container;
  currentLinkPos = { x: 0, width: 0 };
  links: Link[] = [
    {
      hash: 'start',
      title: 'Start',
      description: 'Top of page',
    },
    {
      hash: 'what-we-do',
      title: 'What we do',
    },
    {
      hash: 'about-us',
      title: 'About us',
    },
    {
      hash: 'careers',
      title: 'Careers',
    },
    {
      hash: 'contact',
      title: 'Contact',
    },
  ];

  get currentSection () {
    return this.$store.state.navigation.currentSection;
  }

  get lightTheme () {
    return this.$store.state.navigation.lightTheme;
  }

  @Watch('currentSection')
  onCurrentSectionChange () {
    this.getCurrentLinkPos();
  }

  mounted () {
    this.container = this.$refs.container as HTMLElement;
    this.setScrollTriggerPos();
    this.getCurrentLinkPos();
    this.$nuxt.$on('onResize', this.onResize);
  }

  beforeDestroy () {
    this.$nuxt.$off('onResize', this.onResize);
  }

  onResize () {
    this.setScrollTriggerPos();
    this.getCurrentLinkPos();
  }

  setScrollTriggerPos () {
    const siteMargins = getComputedStyle(document.documentElement).getPropertyValue('--site-margins');
    const position = this.container.clientHeight * 0.5 + parseFloat(siteMargins);
    this.$store.commit('navigation/setScrollTriggerPos', position);
  }

  scrollToSection (id) {
    document.getElementById(id)?.scrollIntoView();
  }

  getCurrentLinkPos () {
    const currentEl = (this.$refs.link as HTMLElement[])[this.currentSection] as HTMLElement;
    this.currentLinkPos = {
      x: currentEl.offsetLeft,
      width: currentEl.offsetWidth,
    };
    gsap.to(this.$refs.currentIndicator as HTMLElement, {
      x: this.currentLinkPos.x,
      width: this.currentLinkPos.width,
      duration: 0.75,
      ease: 'expo.out',
    });
  }
}
