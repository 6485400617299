









































import { Component, Vue } from 'nuxt-property-decorator';

import IconPipe from '@/components/icon/Pipe.vue';
import IconTeamwork from '@/components/icon/Teamwork.vue';
import IconDashboard from '@/components/icon/Dashboard.vue';
import IconRobot from '@/components/icon/Robot.vue';
import IconLeadership from '@/components/icon/Leadership.vue';

@Component({
  components: {
    IconPipe,
    IconTeamwork,
    IconDashboard,
    IconRobot,
    IconLeadership,
  },
})
export default class SectionWhatWeDo extends Vue {
  animate = false;
  activeCard = 0;
  services = [
    /* eslint-disable max-len */
    {
      id: 'data-engineering',
      icon: IconPipe,
      title: 'Data Engineering',
      description: 'Your organization has data, and lots of it. We ensure that it’s made available to your analytics stack with modern technology and methodology.',
      labels: ['ELT', 'Scalability', 'Data Governance', 'DataOps', 'Cloud Architecture'],
    },
    {
      id: 'machine-learning-engineering',
      icon: IconTeamwork,
      title: 'Machine Learning Engineering',
      description: 'Everybody loves their Machine Learning models! But how valuable are they to you and your customers if they’re not in production? As ML Engineers, we take you from quick and dirty proofs-of-concept to scalable ML applications.',
      labels: ['Deployment', 'Observability', 'AB test', 'Scalability', 'Continuous Improvement'],
    },
    {
      id: 'business-intelligence',
      icon: IconDashboard,
      title: 'Business Intelligence',
      description: 'Your metrics and KPIs deserve the royal treatment! We assist you in identifying and implementing them in a modern analytics stack that will help you make those business-critical decisions. We happily perform pilot studies, streamline your dashboards experience and operationalize data from your production systems.',
      labels: ['Insights', 'Dashboarding', 'Data-drivenness'],
    },
    {
      id: 'data-science',
      icon: IconRobot,
      title: 'Data Science',
      description: 'Data is worth nothing - insights are worth everything! As Data Scientists, we work with you to get the most out of your data, be it through classical statistics, big data analytics or bleeding-edge Machine Learning modeling. We bring it to life!',
      labels: ['Modeling', 'Algorithms', 'Metrics', 'Dashboards', 'Data Storytelling'],
    },
    {
      id: 'tech-leadership',
      icon: IconLeadership,
      title: 'Tech Leadership',
      description: 'Your data exists in every corner of your organization. Making it understandable (and enjoyable!) for everyone from your engineers to your board of directors is at the heart of what we do. We know that the shortest path to success in the Information Age is through data and we are happy to take the lead in your tech teams and analytics initiatives to achieve just this.',
      labels: [],
    },
    /* eslint-enable max-len */
  ];

  mounted () {
    this.$nuxt.$once('animateSection:what-we-do', () => {
      this.animate = true;
    });
  }

  setActiveCard (n) {
    this.activeCard = n;
  }
}
