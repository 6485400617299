




























import { Component, Vue } from 'nuxt-property-decorator';

@Component
export default class SectionContact extends Vue {
  animate = false;

  mounted () {
    this.$nuxt.$once('animateSection:contact', () => {
      this.animate = true;
    });
  }
}
