























import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class WhatWeDoNavigation extends Vue {
  @Prop(Array)
    services!: any[];

  @Prop(Number)
    activeCard!: number;

  scrollToCard (id) {
    const el = document.getElementById(id);
    el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}
