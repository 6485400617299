


































import { Component, Vue } from 'nuxt-property-decorator';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

@Component
export default class SectionCareers extends Vue {
  vh = 0;
  backgroundImages = [
    /* eslint-disable max-len */
    {
      src: '/images/careers/3.jpg',
      alt: '',
      width: '960',
      height: '720',
      sizes: 'sm:128px md:198px lg:240px xl:288px 2xl:384px 3xl:480px',
    },
    {
      src: '/images/careers/2.jpg',
      alt: '',
      width: '960',
      height: '1280',
      sizes: 'sm:58px md:89px lg:108px xl:130px 2xl:172px 3xl:216px',
    },
    {
      src: '/images/careers/4.jpg',
      alt: '',
      width: '960',
      height: '640',
      sizes: 'sm:128px md:198px lg:240px xl:288px 2xl:384px 3xl:480px',
    },
    {
      src: '/images/careers/1.jpg',
      alt: '',
      width: '960',
      height: '720',
      sizes: 'sm:172px md:268px lg:324px xl:388px 2xl:518px 3xl:648px',
    },
    {
      src: '/images/careers/5.jpg',
      alt: '',
      width: '960',
      height: '720',
      sizes: 'sm:108px md:168px lg:204px xl:244px 2xl:326px 3xl:408px',
    },
    {
      src: '/images/careers/6.jpg',
      alt: '',
      width: '720',
      height: '960',
      sizes: 'sm:58px md:89px lg:108px xl:130px 2xl:172px 3xl:216px',
    },
    /* eslint-enable max-len */
  ];

  mounted () {
    const container = this.$refs.container as HTMLElement;
    const content = this.$refs.content as HTMLElement;
    const images = this.$refs.backgroundImages as HTMLElement[];
    this.getVh();
    this.$nuxt.$on('onResize', this.getVh);

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: container,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true,
        invalidateOnRefresh: true,
      },
    });
    tl.addLabel('init')
      .fromTo(content as HTMLElement, { y: this.vh * -0.2 }, { y: 0, ease: 'none' }, 'init')
      .fromTo([images[2], [images[3]]], { y: this.vh * 0.15 }, { y: this.vh * 0, ease: 'none' }, 'init')
      .fromTo([images[0], [images[4]]], { y: this.vh * 0.1 }, { y: this.vh * 0, ease: 'none' }, 'init');
  }

  beforeDestroy () {
    this.$nuxt.$off('onResize', this.getVh);
  }

  animateImage (e) {
    const tl = gsap.timeline();
    tl.to(e.target, { rotation: 0.001, scale: 0.96, duration: 0.15, ease: 'power1.out' })
      .to(e.target, { rotation: 0.001, scale: 1, duration: 1.25, ease: 'elastic.out(1, 0.3)' });
  }

  getVh () {
    this.vh = this.$store.state.app.bounds.vh;
  }
}
